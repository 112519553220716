// import api from '../lib/api-client';
// import logger from '../lib/logger';

export default {
  leftDrawer: async (user, abilities) => {
    return user?.preferences?.configs?.drawerExpanded || false;
  },
  badges: async (user, abilities) => {
    const badges: any = [];

    // if (user.can('read', 'v1/objects/customer-orders')) badges.push('unshippedOrders');
    // if (user.can('read', 'v1/objects/customer-invoices')) badges.push('unpaidInvoices');
    // if (user.can('read', 'v1/objects/purchase-orders')) badges.push('totalIncomingPOs');
    // if (user.can('create', 'v1/admin/order/review')) badges.push('pendingReviewOrders', 'pendingReviewIncomingPOs');
    // if (user.hasRule(['inventory-manager', 'supply-manager'])) badges.push('pendingReceivingTransfers');

    //   const counters: any = await api.rest.service('v1/controllers/badge-counters').create({
    //     useEstimatedDocumentCount: false,
    //     badges,
    // })
    //     .catch((e) => logger.error(`badges, get-counters error: ${e.message}`));

    const counters: any = {};

    return {
      totalUnshippedOrders: counters?.unshippedOrders || 0,
      totalUnpaidInvoices: counters?.unpaidInvoices || 0,
      totalPendingPOs: counters?.totalIncomingPOs || 0,
      totalPendingReviewOrders: counters?.pendingReviewOrders || 0,
      totalPendingReviewPOs: counters?.pendingReviewIncomingPOs || 0,
      totalPendingReview: (counters?.pendingReviewOrders || 0) + (counters?.pendingReviewIncomingPOs || 0),
      totalShippedTransfers: counters?.pendingReceivingTransfers || 0,
    };
  },
};
