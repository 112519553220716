export default {
  state: {
    asyncInit: false,
    loading: true,
    user: null,
    notifications: [],
    eventsHistory: [],
    badges: {
      totalTickets: 0,
      totalUnshippedOrders: 0,
    },
    anchorEl: null,
    openPopup: '',
    leftDrawer: false,
    rightDrawer: false,
    cntrlIsPressed: false,
    snackMsg: '',
    leftDrawOpenItem: '',
  },
};
