// import { red, green } from '@mui/material/colors';

// theme.spacing(1) === 8
export default function globalStyles(theme: any): any {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      overflowX: 'scroll',
      // overflowY: 'visible',
    },
    body: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(3),
    },
    dialogPaper: {
      // width: 650,
      overflow: 'scroll !important',
    },
    dialogPaperVisible: {
      width: 633,
      overflow: 'visible !important',
    },
    flex: {
      display: 'flex',
      overflow: 'visible',
    },
    flexWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'visible',
    },
    flexWrapSpaceBetween: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'visible',
      justifyContent: 'space-between',
    },
    flexColumn: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    flexDirectionUnset: { flexDirection: 'unset' },
    flexBlock: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      overflow: 'visible',
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    flexBlock50: {
      width: '100%',
      maxWidth: `calc(50% - ${theme.spacing(0.5)})`,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      overflow: 'visible',
      // marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
      '@media (max-width: 500px)': {
        maxWidth: 'unset',
      },
    },
    flexBox: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      overflow: 'visible',
    },
    navBar: { marginBottom: theme.spacing(1) },
    tabsScroll: {
      overflowX: 'scroll !important',
      overflowY: 'visible !important',
    },
    tabWrapper: {
      height: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
    },
    tab: { minWidth: 10 },
    buttonRight: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    buttonLeft: {
      height: 30.75,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    leftIcon: { marginRight: theme.spacing(1) },
    rightIcon: { marginLeft: theme.spacing(1) },
    spaceBetween: { justifyContent: 'space-between' },
    flexCenter: { justifyContent: 'center' },
    flexAlignCenter: { alignItems: 'center' },
    flexAlignEnd: { alignItems: 'flex-end' },
    flexAlignStart: { alignItems: 'flex-start' },
    flexAlignBaseline: { alignItems: 'baseline' },
    flexEnd: { justifyContent: 'flex-end' },
    flexStart: { justifyContent: 'flex-start' },
    list: { width: '100%', padding: 0 },
    listItem: {
      padding: 0,
      marginBottom: theme.spacing(1),
      '&:last-child': { marginBottom: 0 },
    },
    textFieldMeta: {
      width: 200,
      maxWidth: '100%',
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaFieldMeta: {
      width: 400 + parseInt(theme.spacing(1)),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textShortFieldMeta: {
      width: 100,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textFieldBtn: {
      width: 200,
      minHeight: 30.75,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaFieldBtn: {
      width: 400 + parseInt(theme.spacing(1)),
      minHeight: 30.75,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textFieldDialog50: {
      width: '50%',
      minHeight: 30.75,
      margin: 0,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginRight: 0,
      },
    },
    textFieldDialog: {
      width: '100%',
      minHeight: 30.75,
      margin: 0,
      marginBottom: theme.spacing(1),
    },
    textAreaFieldDialog: {
      width: '100%',
      minWidth: 400 + parseInt(theme.spacing(1)),
      minHeight: 30.75,
      margin: 0,
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: 200,
      minHeight: 30.75,
      maxWidth: '100%',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textField50: {
      width: 100,
      minHeight: 30.75,
      maxWidth: '100%',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaField: {
      width: 400 + parseInt(theme.spacing(1)),
      minHeight: 30.75,
      maxWidth: '100%',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaFieldLarge1: {
      width: 600 + parseInt(theme.spacing(2)),
      minHeight: 30.75,
      maxWidth: '100%',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaFieldLarge2: {
      width: 800 + parseInt(theme.spacing(3)),
      minHeight: 30.75,
      maxWidth: '100%',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaLongField: {
      width: 800 + parseInt(theme.spacing(3)),
      maxWidth: '100%',
      minHeight: 30.75,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaFieldShort: {
      width: 200,
      maxWidth: '100%',
      minHeight: 30.75,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textField40: {
      width: 200,
      maxWidth: '100%',
      minHeight: 30.75,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textFieldShort40: {
      width: 100,
      maxWidth: '100%',
      minHeight: 30.75,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaField40: {
      width: 400 + parseInt(theme.spacing(1)),
      maxWidth: '100%',
      minHeight: 30.75,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaFieldHalf40: {
      width: 300 + parseInt(theme.spacing(2)),
      maxWidth: '100%',
      minHeight: 30.75,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textAreaFieldShort40: {
      width: 200,
      maxWidth: '100%',
      minHeight: 30.75,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    textFieldDialog40: {
      width: '100%',
      maxWidth: '100%',
      minHeight: 30.75,
      '&:last-child': { minHeight: 30.75 },
      margin: 0,
    },
    checkboxField: {
      width: 200,
      minHeight: 30.75,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    formLabel: {
      position: 'absolute',
      fontSize: '0.7rem',
      zIndex: 10,
    },
    blockLabel: {
      margin: 0,
      padding: 0,
      marginBottom: theme.spacing(1),
      textAlign: 'left',
    },
    chip: {
      margin: theme.spacing(0.5),
      alignSelf: 'flex-end',
    },
    chipInput: {
      height: theme.spacing(3),
      margin: `0 calc(${theme.spacing(1)} / 4)`,
    },
    chipInputDeleteIcon: { margin: '0 !important' },
    chipField: {
      maxWidth: 424,
      marginRight: 24,
      '&:last-child': { marginRight: 0 },
    },
    chipInputLabel: { top: '0 !important' },
    chipInputRoot: { padding: `${theme.spacing(1)} !important` },
    table: {
      width: '100%',
      minWidth: '100%',
      overflow: 'scroll',
    },
    tableHead: {
      'white-space': 'nowrap',
      overflow: 'hidden',
      height: 30.75,
      minHeight: 30,
      maxHeight: 30,
    },
    tableHeadColumn: {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    tableWrapper: {
      overflow: 'scroll',
      overflowY: 'hidden',
      // whiteSpace: 'nowrap',
    },
    cardLabel: {
      textAlign: 'center',
      margin: 0,
    },
    gridList: {
      width: '100%',
      maxHeight: 600,
    },
    gridListTile: {
      height: 300,
      width: '300px !important',
      display: 'block',
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    media: {
      display: 'block',
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      margin: '0 auto',
    },
    icon: { color: 'rgba(255, 255, 255, 0.54)' },
    textAlignCenter: { textAlign: 'center' },
    textAlignLeft: { textAlign: 'left' },
    textAlignRight: { textAlign: 'right' },
    textAlignJustify: { textAlign: 'justify' },
    margin: { margin: theme.spacing(3) },
    margin1: { margin: theme.spacing(1) },
    margin2: { margin: theme.spacing(1) },
    marginTop: { marginTop: theme.spacing(3) },
    marginTop1: { marginTop: theme.spacing(1) },
    marginTop2: { marginTop: theme.spacing(1) },
    paddingTop2: { paddingTop: theme.spacing(1) },
    marginRight: { marginRight: theme.spacing(3) },
    marginRight05: { marginRight: theme.spacing(1 / 2) },
    marginRight1: { marginRight: theme.spacing(1) },
    marginRight2: { marginRight: theme.spacing(1) },
    marginBottom: { marginBottom: theme.spacing(3) },
    marginBottom1: { marginBottom: theme.spacing(1) },
    marginBottom2: { marginBottom: theme.spacing(1) },
    marginBottom3: { marginBottom: theme.spacing(3) },
    marginLeft: { marginLeft: theme.spacing(3) },
    marginLeft1: { marginLeft: theme.spacing(1) },
    marginLeft2: { marginLeft: theme.spacing(1) },
    noMargin: { margin: '0 !important' },
    noMarginBottom: { marginBottom: '0 !important' },
    noMarginRight: { marginRight: '0 !important' },
    noMarginLeft: { marginLeft: '0 !important' },
    noMarginTop: { marginTop: '0 !important' },
    marginTopBottom1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    noPaddingBottom: { paddingBottom: '0 !important' },
    noPaddingTop: { paddingTop: '0 !important' },
    padding: { padding: theme.spacing(3) },
    padding1: { padding: `${theme.spacing(1)} !important` },
    padding2: { padding: theme.spacing(1) },
    paddingTop1: { padding: theme.spacing(1) },
    paddingBottom1: { paddingBottom: theme.spacing(1) },
    paddingBottom2: { paddingBottom: `${theme.spacing(1)} !important` },
    noPadding: { padding: '0 !important' },
    noBorder: { border: 'none' },
    fullWidth: { width: '100%' },
    halfWidth: { width: '50%' },
    borderDashed: { borderStyle: 'dashed' },
    cursorDefault: { cursor: 'default' },
    cursorPointer: { cursor: 'pointer' },
    rotate180: {
      '-webkit-transform': 'rotate(180deg)',
      '-moz-transform': 'rotate(180deg)',
      '-o-transform': 'rotate(180deg)',
      '-ms-transform': 'rotate(180deg)',
      transform: 'rotate(180deg)',
    },
    preventNewLine: {
      'white-space': 'nowrap',
      overflow: 'hidden',
    },
    overflowVisible: { overflow: 'visible !important' },
    overflowScroll: { overflow: 'scroll !important' },
    overflowHide: { overflow: 'hidden' },
    spinner: { animation: 'spin 1.5s linear infinite' },
    none: {
      visibility: 'hidden !important',
      display: 'none !important',
    },
    barcode: {
      margin: '1mm',
      paddingTop: '1mm',
      display: 'inline-block',
      width: 'auto',
      border: '1px solid black',
      fontSize: '12pt',
      '& p': { margin: '1mm 2.5mm' },
      '& svg': {
        marginLeft: 1,
        width: 'calc(100% - 3px)',
      },
    },
    barcodeMini: {
      marginTop: '10mm !important',
      margin: '1mm !important',
      padding: '0mm !important',
      display: 'inline-block',
      width: 'auto',
      border: '1px solid black',
      fontSize: '12pt',
      '& p': { margin: '0 1mm !important' },
    },
    creditBalance: {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      margin: 0,
      marginRight: theme.spacing(1),
      '&:last-child': { marginRight: 0 },
    },
    image: {
      minWidth: 100,
      maxWidth: 200,
      height: 100,
      backgroundSize: 'contain',
    },
    imageStub: {
      width: 100,
      height: 100,
      backgroundColor: '#E0E0E0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    visibleDisabledText: {
      color: 'rgba(0, 0, 0, 0.54) !important',
      pointerEvents: 'none',
    },
  };
}
