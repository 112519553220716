import { Ability } from '@casl/ability';

/**
 * helper to check if user have permission
 * to access the page, and specified ability
 * this is just to hide/show UI components
 * server side also have ability verification to
 * restrict access
 */
export default function abilities(user: any = {}): any {
  return {
    hasRule(rules: string[]): boolean {
      return Boolean(rules.find(r => (user?.rules || []).includes(r))) || (user?.rules || []).includes('super-admin');
    },
    can(action: string, resource: string): boolean {
      if (user?._id) {
        const TYPE_KEY = Symbol.for('type');
        const subjectName = (subject?: any): any => {
          if (!subject || typeof subject === 'string') {
            return subject;
          }
          return subject[TYPE_KEY];
        };
        const userAbility = new Ability(user?.abilities || [], { subjectName });
        // const planAbility = (
        //   user?.tenant
        //   && user?.tenant.subscription
        //   && user?.tenant?.subscription?.isActive
        //   && Array.isArray(user?.tenant.subscription.plan?.abilities)
        //   && new Ability(user?.tenant.subscription.plan?.abilities, { subjectName })
        // ) || false;

        // accessGroup_super-admin-read gives full access within tenant scope
        return (
          userAbility.can(action, resource) ||
          userAbility.can(action, `accessGroup_${resource}`) ||
          userAbility.can(action, 'accessGroup_super-admin')
        );
        //   && (
        //   !planAbility
        //   || planAbility.can(action, resource)
        //   || planAbility.can(action, `accessGroup_${resource}`)
        //   || planAbility.can(action, 'accessGroup_super-admin')
        // );
      }
      return false;
    },
  };
}
