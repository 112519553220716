export default {
  'v1/controllers/endpoints': 'getPublicApiEndpoints',
  'v1/countries/list': 'countries',
  'v1/objects/api-keys': 'apiKeys',
  'v1/objects/customers': 'customers',
  'v1/objects/access-rules': 'rules',
  'v1/objects/users': 'users',
  'v1/objects/uploads': 'uploads',
  'v1/objects/access-groups': 'accessGroups',
  'v1/objects/plans': 'plans',
  'v1/objects/products': 'products',
  'v1/objects/product-categories': 'productCategories',
  'v1/objects/product-attributes': 'productAttributes',
  'v1/objects/product-brands': 'productBrands',
  'v1/objects/product-tags': 'productTags',
  'v1/objects/product-segments': 'productSegments',
  'v1/objects/product-vendors': 'productVendors',
  'v1/objects/coupon-codes': 'couponCodes',
  'v1/objects/customer-orders': 'customerOrders',
  'v1/objects/customer-invoices': 'customerInvoices',
  'v1/objects/customer-credits': 'customerCredits',
  'v1/objects/customer-payments': 'customerPayments',
  'v1/objects/applied-payments': 'appliedPayments',
  'v1/objects/applied-payments?modal': 'appliedPaymentsModal',
  'v1/objects/transactions': 'transactions',
  'v1/objects/tickets': 'tickets',
  'v1/objects/ticket-messages': 'ticketMessages',
  'v1/objects/themes': 'tenantThemes',
  'v1/objects/reviews': 'reviews',
  'v1/objects/call-flows': 'callFlows',
  'v1/objects/events': 'events',
  'v1/objects/sftp-users': 'sftpUsers',
  'v1/objects/rules-engine-rules': 'rulesEngineRules',
  'v1/objects/deployments': 'deployments',
  'v1/objects/domains': 'domains',
  'v1/objects/warehouses': 'warehouses',
  'v1/objects/locations': 'locations',
  'v1/objects/stock-log': 'stockLog',
  'v1/objects/stock-lots': 'stockLots',
  'v1/objects/stock-counters': 'stockCounters',
  'v1/inventory/lookup': 'lookup',
  'v1/inventory/lookup-counters': 'lookupCounters',
  'v1/objects/sales-channels': 'salesChannels',
  'v1/objects/product-trackers': 'trackers',
  'v1/objects/adapters': 'adapters',
  'v1/objects/notes': 'notes',
  'v1/objects/postage-log': 'postageLog',
  'v1/objects/shipping-log': 'shippingLog',
  'v1/objects/postage-scan-forms': 'scanForms',
  'v1/objects/email-templates': 'emailTemplates',
  'v1/objects/default-email-templates': 'defaultEmailTemplates',
  'v1/objects/reports': 'reports',
  'v1/objects/purchase-orders': 'purchaseOrders',
  'v1/objects/qbwc-requests': 'qbwcRequests',
  'v1/objects/acc-objects': 'accObjects',
  'v1/objects/customer-tags': 'customerTags',
  'v1/objects/emails-logs': 'emailsLogs',
  'v1/objects/build-logs': 'build-logs',
  'v1/objects/build-templates': 'buildTemplates',
  'v1/objects/vendors': 'vendors',
  'v1/objects/price-lists': 'priceLists',
  'v1/objects/fulfillment-orders': 'fulfillmentOrders',
  'v1/objects/fulfillment-zones': 'fulfillmentZones',
  'v1/objects/product-bulk-edits': 'productBulkEdits',
  'v1/objects/email-senders': 'emailSenders',
  'v1/objects/event-annotations': 'eventAnnotations',
  'v1/objects/general-tags': 'generalTags',
  'v1/objects/pick-lists': 'pickLists',
  'v1/objects/customer-invites': 'customerInvites',
  'v1/objects/user-groups': 'userGroups',
  'v1/objects/tenant-invoices': 'tenantInvoices',
  'v1/objects/tenant-transactions': 'tenantTransactions',
};
